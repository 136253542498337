<template>
  <v-container fluid class="px-6 py-6">
    <v-row>
      <v-col lg="12" cols="12">
        <v-card class="border-radius-xl card-shadow">
          <div class="d-flex pb-0 px-4 py-4">
            <v-tabs v-model="tab" right class="overflow-hidden">
              <v-tabs-slider></v-tabs-slider>

              <v-tab v-on:click="getGenel" href="#tab-1"> GENEL </v-tab>

              <v-tab @click="getAnalizor" href="#tab-2"> ANALİZÖR </v-tab>

              <v-tab href="#tab-3"> R.G.R </v-tab>

              <v-tab href="#tab-4"> JENERATÖR </v-tab>

              <v-tab @click="getGES" href="#tab-5"> GES </v-tab>
            </v-tabs>
          </div>

          <div class="px-2 py-4 mt-2">
            <v-tabs-items v-model="tab">
              <v-tab-item key="1" value="tab-1">
                <v-row class="px-12 py-2 mt-1">
                  <v-col md="10" cols="2" class="mx-auto" v-show="!Card1">
                    <v-progress-linear indeterminate color="green"></v-progress-linear>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6" cols="2" v-show="!Card1">
                    <v-skeleton-loader class="mx-auto" max-width="auto" type="sentences"></v-skeleton-loader>
                  </v-col>
                  <v-col md="6" cols="12" v-show="Card1">
                    <v-alert v-model="alert" dense text border="left" :color="types" :icon="icons">
                      {{ alarm }}
                    </v-alert>
                  </v-col>
                  <v-col md="6" cols="2" v-show="!Card1">
                    <v-skeleton-loader class="mx-auto" max-width="auto" type="sentences"></v-skeleton-loader>
                  </v-col>
                  <v-col md="6" cols="12" v-show="Card1">
                    <v-alert v-model="alert2" dense text border="left" :color="types2" :icon="icons2">
                      {{ alarm2 }}
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col md="3" cols="12" v-show="!Card1">
                    <v-skeleton-loader class="mx-auto" max-width="auto" type="article, action"></v-skeleton-loader>
                  </v-col>

                  <v-col md="3" cols="12" v-show="Card1">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-2 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>

                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="capower"> </span>
                          <span class="text-lg ms-1"></span>
                        </h1>

                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          kW
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Anlık Güç Tüketim
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12" v-show="!Card1">
                    <v-skeleton-loader class="mx-auto" max-width="auto" type="article, action"></v-skeleton-loader>
                  </v-col>
                  <v-col md="3" cols="12" v-show="Card1">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-2 text-center">
                        <v-badge dot right :color="badgecolor">
                          <v-icon color="green darken-2" size="28">mdi-solar-panel</v-icon>
                        </v-badge>

                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="cgpower2"> </span>
                          <span class="text-lg ms-1"></span>
                        </h1>

                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          kW
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Anlık Güç GES Kazanç
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12" v-show="!Card1">
                    <v-skeleton-loader class="mx-auto" max-width="auto" type="article, action"></v-skeleton-loader>
                  </v-col>
                  <v-col md="3" cols="12" v-show="Card1">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-7 py-4 text-center mx-auto">
                        <round-slider v-model="sliderValue" min="0" max="10" step="0.01" readOnly="true"
                          borderColor="orange" rangeColor="green" start-angle="0" end-angle="+180"
                          circleShape="half-top" line-cap="round" width="10" radius="100"></round-slider>

                        <p class="opacity-8 mb-0 text-sm text-typo">THD %</p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12" v-show="!Card1">
                    <v-skeleton-loader class="mx-auto" max-width="auto" type="article, action"></v-skeleton-loader>
                  </v-col>
                  <v-col md="3" cols="12" v-show="Card1">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-7 py-4 text-center mx-auto">
                        <round-slider v-model="sliderValue2" min="0" max="2" step="0.01" readOnly="true"
                          borderColor="orange" rangeColor="green" start-angle="0" end-angle="+180"
                          circleShape="half-top" line-cap="round" width="10" radius="100"></round-slider>

                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Power Factor
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6" cols="2" v-show="!Card1">
                    <v-skeleton-loader class="mx-auto" max-width="auto" type="sentences"></v-skeleton-loader>
                  </v-col>
                  <v-col md="6" cols="12" v-show="Card1">
                    <v-alert v-model="asdd" dense text border="left" color="green" icon="mdi-information-outline">
                      Sağlıklı: [13] Orta: [0] Sağlıksız: [0]
                    </v-alert>
                  </v-col>
                  <v-col md="6" cols="2" v-show="!Card1">
                    <v-skeleton-loader class="mx-auto" max-width="auto" type="sentences"></v-skeleton-loader>
                  </v-col>
                  <v-col md="6" cols="12" v-show="Card1">
                    <v-alert dense text border="left" color="green" icon="mdi-check-circle">
                      Ceza yok. Her şey normal.
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col md="6" cols="12" v-show="!Card1">
                    <v-skeleton-loader class="mx-auto" max-width="auto" type="article, action"></v-skeleton-loader>
                  </v-col>

                  <v-col md="6" cols="10" v-show="Card1">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-1 py-10 text-center">
                        <v-row>
                          <v-col md="4" cols="4">
                            <v-icon size="58" color="green lighten-1">mdi-battery-90</v-icon>
                            <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                              Sağlıklı
                            </h6>
                            <p class="opacity-8 mb-0 text-sm text-typo">
                              Kademe 1-13
                            </p>
                          </v-col>
                          <v-col md="4" cols="4">
                            <v-icon size="58" color="orange lighten-1">mdi-battery-50</v-icon>
                            <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                              Orta
                            </h6>
                            <p class="opacity-8 mb-0 text-sm text-typo">
                              Kademe 1-13
                            </p>
                          </v-col>
                          <v-col md="4" cols="4">
                            <v-icon size="58" color="red lighten-1">mdi-battery-10</v-icon>
                            <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                              Sağlıksız
                            </h6>
                            <p class="opacity-8 mb-0 text-sm text-typo">
                              Kademe 1-13
                            </p>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card>
                  </v-col>

                  <v-col md="3" cols="12" v-show="!Card1">
                    <v-skeleton-loader class="mx-auto" max-width="auto" type="article, action"></v-skeleton-loader>
                  </v-col>
                  <v-col md="3" cols="12" v-show="Card1">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-7 py-4 text-center mx-auto">
                        <round-slider v-model="sliderValue3" min="0" max="30" step="0.01" readOnly="true"
                          borderColor="orange" rangeColor="green" start-angle="0" end-angle="+180"
                          circleShape="half-top" line-cap="round" width="10" radius="100"></round-slider>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          Toplam %
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Endüktif - Reaktif
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12" v-show="!Card1">
                    <v-skeleton-loader class="mx-auto" max-width="auto" type="article, action"></v-skeleton-loader>
                  </v-col>
                  <v-col md="3" cols="12" v-show="Card1">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-7 py-4 text-center mx-auto">
                        <round-slider v-model="sliderValue4" min="0" max="30" step="0.01" readOnly="true"
                          borderColor="orange" rangeColor="green" start-angle="0" end-angle="+180"
                          circleShape="half-top" line-cap="round" width="10" radius="100"></round-slider>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          Toplam %
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Kapasitif - Reaktif
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6" cols="2" v-show="!Card1">
                    <v-skeleton-loader class="mx-auto" max-width="auto" type="sentences"></v-skeleton-loader>
                  </v-col>
                  <v-col md="6" cols="12" v-show="Card1">
                    <v-alert v-model="asdd" dense text border="left" color="orange" icon="mdi-information-outline">
                      R Fazı: [Aşırı] S Fazı: [Normal] T Fazı: [Normal]
                    </v-alert>
                  </v-col>
                  <v-col md="6" cols="2" v-show="!Card1">
                    <v-skeleton-loader class="mx-auto" max-width="auto" type="sentences"></v-skeleton-loader>
                  </v-col>
                  <v-col md="6" cols="12" v-show="Card1">
                    <v-alert dense text border="left" color="green" icon="mdi-information-outline">
                      R Fazı: [Normal] S Fazı: [Normal] T Fazı: [Normal]
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6" cols="12" v-show="!Card1">
                    <v-skeleton-loader class="mx-auto" max-width="auto" type="article, action"></v-skeleton-loader>
                  </v-col>

                  <v-col md="6" cols="12" v-show="Card1">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-2 py-8 text-center">
                        <v-col md="12" cols="2">
                          <v-slider thumb-color="green lighten-1" thumb-label="always" min="0" max="+50" step="0.01"
                            :value="rFaziK" thumb-size="30" tick-size="1" readonly>
                            <template v-slot:prepend>
                              <v-icon color="orange lighten-1" size="28">
                                mdi-alpha-r-circle-outline
                              </v-icon>
                            </template>
                          </v-slider>
                        </v-col>
                        <v-col md="12" cols="2">
                          <v-slider thumb-color="green lighten-1" thumb-label="always" min="0" max="50" step="0.01"
                            :value="sFaziK" thumb-size="30" tick-size="1" readonly>
                            <template v-slot:prepend>
                              <v-icon color="orange lighten-1" size="28">
                                mdi-alpha-s-circle-outline
                              </v-icon>
                            </template>
                          </v-slider>
                        </v-col>
                        <v-col md="12" cols="2">
                          <v-slider thumb-color="green lighten-1" thumb-label="always" min="0" max="+50" step="0.01"
                            :value="tFaziK" thumb-size="30" tick-size="1" readonly>
                            <template v-slot:prepend>
                              <v-icon color="orange lighten-1" size="28">
                                mdi-alpha-t-circle-outline
                              </v-icon>
                            </template>
                          </v-slider>
                        </v-col>
                      </div>
                      <div class="px-2 text-center">
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          Kapasitif
                        </h6>
                        <p class="opacity-8 text-sm text-typo">
                          Biriken Enerji Oranları
                        </p>
                      </div>
                    </v-card>
                  </v-col>

                  <v-col md="6" cols="12" v-show="!Card1">
                    <v-skeleton-loader class="mx-auto" max-width="auto" type="article, action"></v-skeleton-loader>
                  </v-col>

                  <v-col md="6" cols="12" v-show="Card1">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-2 py-8 text-center">
                        <v-col md="12" cols="2">
                          <v-slider thumb-color="green lighten-1" thumb-label="always" min="0" max="+50" step="0.01"
                            :value="rFaziI" thumb-size="30" tick-size="1" readonly>
                            <template v-slot:prepend>
                              <v-icon color="orange lighten-1" size="28">
                                mdi-alpha-r-circle-outline
                              </v-icon>
                            </template>
                          </v-slider>
                        </v-col>
                        <v-col md="12" cols="2">
                          <v-slider thumb-color="green lighten-1" thumb-label="always" min="0" max="+50" step="0.01"
                            :value="sFaziI" thumb-size="30" tick-size="1" readonly>
                            <template v-slot:prepend>
                              <v-icon color="orange lighten-1" size="28">
                                mdi-alpha-s-circle-outline
                              </v-icon>
                            </template>
                          </v-slider>
                        </v-col>
                        <v-col md="12" cols="2">
                          <v-slider thumb-color="green lighten-1" thumb-label="always" min="0" max="+50" step="0.01"
                            :value="tFaziI" thumb-size="30" tick-size="1" readonly>
                            <template v-slot:prepend>
                              <v-icon color="orange lighten-1" size="28">
                                mdi-alpha-t-circle-outline
                              </v-icon>
                            </template>
                          </v-slider>
                        </v-col>
                      </div>
                      <div class="px-2 text-center">
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          İndüktif
                        </h6>
                        <p class="opacity-8 text-sm text-typo">
                          Biriken Enerji Oranları
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="2" value="tab-2">
                <v-row>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-2 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>

                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="z"> </span>
                          <span class="text-lg ms-1"></span>
                        </h1>

                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          kWh
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">Giren</p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-2 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>

                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="x"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          kWh
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">Çıkan</p>
                      </div>
                    </v-card>
                  </v-col>

                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-2 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>

                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="y"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          kVarh
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">Giren</p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="q"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          kVarh
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">Çıkan</p>
                      </div>
                    </v-card>
                  </v-col>

                  <v-col md="4" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="v1"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          V
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Faz 1 - Nötr
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="v2"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          V
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Faz 2 - Nötr
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="v3"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          V
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Faz 3 - Nötr
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="v4"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          V
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">Faz 1-2</p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="v5"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          V
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">Faz 2-3</p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="v6"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          V
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">Faz 3-1</p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="i1"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          A
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">Akım 1</p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="i2"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          A
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">Akım 2</p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="i3"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          A
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">Akım 3</p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="i4"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          A
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Nötr Akımı
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          A
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Toprak Akımı
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          A
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Toplam Ort. Akım
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="p1"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          kW
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">Güç 1</p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="p2"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          kW
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">Güç 2</p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="p3"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          kW
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">Güç 3</p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="p4"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          kW
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Toplam Güç
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="p5"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          kW
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Last Demand
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="p6"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          kW
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Present Demand
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="p7"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          kW
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Predicted Demand
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="p8"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          kW
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Peak Demand
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="f"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          Hz
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">Frekans</p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="thd"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          %
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">THD</p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="pf1"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          pf
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Toplam Min Power Factor
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="pf2"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          pf
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Toplam Min Disp. Power Factor
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="3" value="tab-3">
                <v-row>
                  <v-col md="4" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-2 text-center">
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h5
                          ">
                          <span id="">Sağlıklı</span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <v-icon color="green darken-1" size="80">mdi-battery-90</v-icon>

                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          L-1
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Anlık Güç Tüketim
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-2 text-center">
                        <h1 class="
                            text-gradient text-warning
                            font-weight-bolder
                            text-h5
                          ">
                          <span id="">İyi</span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <v-icon color="orange darken-1" size="80">mdi-battery-60</v-icon>

                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          L-2
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Anlık Güç Tüketim
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-2 text-center">
                        <h1 class="
                            text-gradient text-danger
                            font-weight-bolder
                            text-h5
                          ">
                          <span id="">Sağlıksız</span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <v-icon color="red darken-1" size="80">mdi-battery-20</v-icon>

                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          L-3
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Anlık Güç Tüketim
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="4" value="tab-4">
                <v-row>
                  <v-col md="6" cols="2">
                    <v-card class="pa-1 transparent">
                      <v-alert dense text border="left" color="green" icon="mdi-lightning-bolt-circle">
                        <strong>MOD:</strong>
                      </v-alert>
                    </v-card>
                  </v-col>

                  <v-col md="6" cols="2">
                    <v-card class="pa-1 transparent">
                      <v-alert dense text border="left" color="green" icon="mdi-lightning-bolt-circle">
                        <strong>DURUM:</strong>
                      </v-alert>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id=""></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          kW
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Anlık Güç
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id=""></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          MWh
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Günlük Enerji
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id=""></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          MWh
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Toplam Enerji
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id=""></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          t
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          CO2 Kazanımı
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item key="5" value="tab-5">
                <v-row>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="cpower"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          kW
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Anlık Güç
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="edaily"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          kWh
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Günlük Enerji
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="etotal"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          MWh
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          Toplam Enerji
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-3 text-center">
                        <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id="carbon"></span>
                          <span class="text-lg ms-1"></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          t
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo">
                          CO2 Kazanımı
                        </p>
                      </div>
                    </v-card>
                  </v-col>

                  <v-col md="6" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-2 text-center">
                        <v-icon color="green darken-2" size="28">mdi-solar-panel</v-icon>

                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id=""> </span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          İnverter - 1
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo"></p>
                      </div>

                      <div class="px-4 py-2">
                        <v-row>
                          <v-col md="4" cols="12">
                            <v-card outlined class="border-radius-xl">
                              <div class="px-4 py-3 text-center">
                                <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                                <h1 class="
                                    text-gradient text-success
                                    font-weight-bolder
                                    text-h1
                                  ">
                                  <span id="g1p1"></span>
                                  <span class="text-lg ms-1"></span>
                                </h1>
                                <h6 class="
                                    mb-0
                                    text-h6
                                    font-weight-bolder
                                    text-typo
                                  ">
                                  kW
                                </h6>
                                <p class="opacity-8 mb-0 text-sm text-typo">
                                  Anlık Güç
                                </p>
                              </div>
                            </v-card>
                          </v-col>
                          <v-col md="4" cols="12">
                            <v-card outlined class="border-radius-xl">
                              <div class="px-4 py-3 text-center">
                                <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                                <h1 class="
                                    text-gradient text-success
                                    font-weight-bolder
                                    text-h1
                                  ">
                                  <span id="g1pd1"></span>
                                  <span class="text-lg ms-1"></span>
                                </h1>
                                <h6 class="
                                    mb-0
                                    text-h6
                                    font-weight-bolder
                                    text-typo
                                  ">
                                  kWh
                                </h6>
                                <p class="opacity-8 mb-0 text-sm text-typo">
                                  Günlük Enerji
                                </p>
                              </div>
                            </v-card>
                          </v-col>

                          <v-col md="4" cols="12">
                            <v-card outlined class="border-radius-xl">
                              <div class="px-4 py-3 text-center">
                                <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                                <h1 class="
                                    text-gradient text-success
                                    font-weight-bolder
                                    text-h1
                                  ">
                                  <span id="g1pt1"></span>
                                  <span class="text-lg ms-1"></span>
                                </h1>
                                <h6 class="
                                    mb-0
                                    text-h6
                                    font-weight-bolder
                                    text-typo
                                  ">
                                  MWh
                                </h6>
                                <p class="opacity-8 mb-0 text-sm text-typo">
                                  Toplam Enerji
                                </p>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="6" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-2 text-center">
                        <v-icon color="green darken-2" size="28">mdi-solar-panel</v-icon>

                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id=""> </span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          İnverter - 2
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo"></p>
                      </div>

                      <div class="px-4 py-2">
                        <v-row>
                          <v-col md="4" cols="12">
                            <v-card outlined class="border-radius-xl">
                              <div class="px-4 py-3 text-center">
                                <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                                <h1 class="
                                    text-gradient text-success
                                    font-weight-bolder
                                    text-h1
                                  ">
                                  <span id="g2p1"></span>
                                  <span class="text-lg ms-1"></span>
                                </h1>
                                <h6 class="
                                    mb-0
                                    text-h6
                                    font-weight-bolder
                                    text-typo
                                  ">
                                  kW
                                </h6>
                                <p class="opacity-8 mb-0 text-sm text-typo">
                                  Anlık Güç
                                </p>
                              </div>
                            </v-card>
                          </v-col>
                          <v-col md="4" cols="12">
                            <v-card outlined class="border-radius-xl">
                              <div class="px-4 py-3 text-center">
                                <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                                <h1 class="
                                    text-gradient text-success
                                    font-weight-bolder
                                    text-h1
                                  ">
                                  <span id="g2pd1"></span>
                                  <span class="text-lg ms-1"></span>
                                </h1>
                                <h6 class="
                                    mb-0
                                    text-h6
                                    font-weight-bolder
                                    text-typo
                                  ">
                                  kWh
                                </h6>
                                <p class="opacity-8 mb-0 text-sm text-typo">
                                  Günlük Enerji
                                </p>
                              </div>
                            </v-card>
                          </v-col>

                          <v-col md="4" cols="12">
                            <v-card outlined class="border-radius-xl">
                              <div class="px-4 py-3 text-center">
                                <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                                <h1 class="
                                    text-gradient text-success
                                    font-weight-bolder
                                    text-h1
                                  ">
                                  <span id="g2pt1"></span>
                                  <span class="text-lg ms-1"></span>
                                </h1>
                                <h6 class="
                                    mb-0
                                    text-h6
                                    font-weight-bolder
                                    text-typo
                                  ">
                                  MWh
                                </h6>
                                <p class="opacity-8 mb-0 text-sm text-typo">
                                  Toplam Enerji
                                </p>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col md="6" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-2 text-center">
                        <v-icon color="green darken-2" size="28">mdi-solar-panel</v-icon>

                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id=""> </span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          İnverter - 3
                        </h6>
                        <p class="opacity-8 mb-0 text-sm text-typo"></p>
                      </div>

                      <div class="px-4 py-2">
                        <v-row>
                          <v-col md="4" cols="12">
                            <v-card outlined class="border-radius-xl">
                              <div class="px-4 py-3 text-center">
                                <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                                <h1 class="
                                    text-gradient text-success
                                    font-weight-bolder
                                    text-h1
                                  ">
                                  <span id="g3p1"></span>
                                  <span class="text-lg ms-1"></span>
                                </h1>
                                <h6 class="
                                    mb-0
                                    text-h6
                                    font-weight-bolder
                                    text-typo
                                  ">
                                  kW
                                </h6>
                                <p class="opacity-8 mb-0 text-sm text-typo">
                                  Anlık Güç
                                </p>
                              </div>
                            </v-card>
                          </v-col>
                          <v-col md="4" cols="12">
                            <v-card outlined class="border-radius-xl">
                              <div class="px-4 py-3 text-center">
                                <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                                <h1 class="
                                    text-gradient text-success
                                    font-weight-bolder
                                    text-h1
                                  ">
                                  <span id="g3pd1"></span>
                                  <span class="text-lg ms-1"></span>
                                </h1>
                                <h6 class="
                                    mb-0
                                    text-h6
                                    font-weight-bolder
                                    text-typo
                                  ">
                                  MWh
                                </h6>
                                <p class="opacity-8 mb-0 text-sm text-typo">
                                  Günlük Enerji
                                </p>
                              </div>
                            </v-card>
                          </v-col>

                          <v-col md="4" cols="12">
                            <v-card outlined class="border-radius-xl">
                              <div class="px-4 py-3 text-center">
                                <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                                <h1 class="
                                    text-gradient text-success
                                    font-weight-bolder
                                    text-h1
                                  ">
                                  <span id="g3pt1"></span>
                                  <span class="text-lg ms-1"></span>
                                </h1>
                                <h6 class="
                                    mb-0
                                    text-h6
                                    font-weight-bolder
                                    text-typo
                                  ">
                                  MWh
                                </h6>
                                <p class="opacity-8 mb-0 text-sm text-typo">
                                  Toplam Enerji
                                </p>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card>
                  </v-col>

                  <v-col md="6" cols="12">
                    <v-card outlined class="border-radius-xl">
                      <div class="px-4 py-2 text-center">
                        <v-icon color="green darken-2" size="28">mdi-solar-panel</v-icon>

                        <h1 class="
                            text-gradient text-success
                            font-weight-bolder
                            text-h1
                          ">
                          <span id=""></span>
                        </h1>
                        <h6 class="mb-0 text-h6 font-weight-bolder text-typo">
                          İnverter - 4
                        </h6>
                      </div>
                      <div class="px-4 py-2">
                        <v-row>
                          <v-col md="4" cols="12">
                            <v-card outlined class="border-radius-xl">
                              <div class="px-4 py-3 text-center">
                                <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                                <h1 class="
                                    text-gradient text-success
                                    font-weight-bolder
                                    text-h1
                                  ">
                                  <span id="g4p1"></span>
                                  <span class="text-lg ms-1"></span>
                                </h1>
                                <h6 class="
                                    mb-0
                                    text-h6
                                    font-weight-bolder
                                    text-typo
                                  ">
                                  kW
                                </h6>
                                <p class="opacity-8 mb-0 text-sm text-typo">
                                  Anlık Güç
                                </p>
                              </div>
                            </v-card>
                          </v-col>
                          <v-col md="4" cols="12">
                            <v-card outlined class="border-radius-xl">
                              <div class="px-4 py-3 text-center">
                                <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                                <h1 class="
                                    text-gradient text-success
                                    font-weight-bolder
                                    text-h1
                                  ">
                                  <span id="g4pd1"></span>
                                  <span class="text-lg ms-1"></span>
                                </h1>
                                <h6 class="
                                    mb-0
                                    text-h6
                                    font-weight-bolder
                                    text-typo
                                  ">
                                  MWh
                                </h6>
                                <p class="opacity-8 mb-0 text-sm text-typo">
                                  Günlük Enerji
                                </p>
                              </div>
                            </v-card>
                          </v-col>

                          <v-col md="4" cols="12">
                            <v-card outlined class="border-radius-xl">
                              <div class="px-4 py-3 text-center">
                                <v-icon color="green darken-2" size="28">mdi-lightning-bolt</v-icon>
                                <h1 class="
                                    text-gradient text-success
                                    font-weight-bolder
                                    text-h1
                                  ">
                                  <span id="g4pt1"></span>
                                  <span class="text-lg ms-1"></span>
                                </h1>
                                <h6 class="
                                    mb-0
                                    text-h6
                                    font-weight-bolder
                                    text-typo
                                  ">
                                  MWh
                                </h6>
                                <p class="opacity-8 mb-0 text-sm text-typo">
                                  Toplam Enerji
                                </p>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import RoundSlider from 'vue-round-slider';
import mqtt from 'mqtt';

var asd,
  asdd,
  sorgu,
  sorgu1,
  harmonik,
  powerFactor,
  enduktif,
  kapasitif,
  r1ia,
  r1ca,
  s2ia,
  s2ca,
  t3ia,
  t3ca;

export default {
  name: 'Dashboard',
  components: {
    RoundSlider,
  },

  data() {
    return {
      sliderValue: '',
      tab: null,
      alert: true,
      alert2: true,
      types: 'red',
      icons: 'mdi-check-circle',
      types2: 'green',
      icons2: 'mdi-alert-circle',
      alarm: '',
      alarm2: '',
      sliderValue2: '',
      sliderValue3: '',
      sliderValue4: '',
      Card1: false,
      badgecolor: '',
      rFaziK: '',
      sFaziK: '',
      tFaziK: '',
      rFaziI: '',
      sFaziI: '',
      tFaziI: '',
      asd: '',
      asdd: '',
      sorgu: '',
      sorgu1: '',
      harmonik: '',
      powerFactor: '',
      enduktif: '',
      kapasitif: '',
      r1ia: '',
      r1ca: '',
      s2ia: '',
      s2ca: '',
      t3ia: '',
      t3ca: '',
      mqttClient: {
        connected: false,
      },
    };
  },

  mounted: function () {
    this.getMqttConfig();
  },

  methods: {
    async getMqttConfig() {
      try {
        const { data } = await this.$axios.get('api/setting');
        this.createConnection(data);
      } catch (error) {
        console.log({ error });
      }
    },
    createConnection(config) {
      console.log({ config });
      try {
        this.mqttClient = mqtt.connect(config.url, {
          username: config.username,
          password: config.password,
        });
        console.log('mqttClient', this.mqttClient);
      } catch (error) {
        console.log('mqtt.connect error', error);
      }
      this.mqttClient.on('connect', () => {
        console.log('Connection succeeded!');
        this.getGenel();
        this.getVarkombi();
      });
      this.mqttClient.on('error', (error) => {
        console.log('Connection failed', error);
      });
    },

    getSliderValue() {
      do {
        this.sliderValue = this.harmonik;
        this.sliderValue2 = this.powerFactor;
        this.sliderValue3 = this.enduktif;
        this.sliderValue4 = this.kapasitif;
        this.sorgu = 2;
      } while (this.sorgu == 1);
    },

    getGenel() {
      this.mqttClient.subscribe('/atak/inv');
      this.mqttClient.on('message', (topic, message) => {
        const GENEL = JSON.parse(message);
        if (GENEL.length == 4) {
          var cgpower = (GENEL[2] / 1000).toFixed(1);
          if ('/atak/inv' == topic) {
            this.sorgu1 = 1;
            document.getElementById(`cgpower2`).innerHTML = JSON.parse(cgpower);
            asd = JSON.parse(cgpower);
          }
        }
      });

      this.mqttClient.subscribe('/atak/analizor');
      this.mqttClient.on('message', (topic, message) => {
        const GAnalizor = JSON.parse(message);
        if (GAnalizor.length == 26) {
          let capower = (GAnalizor[17] / 1).toFixed(1);
          let thdg = (GAnalizor[23] / 1).toFixed(3);
          let pfdg = (GAnalizor[24] / 1).toFixed(3);

          if ('/atak/analizor' == topic) {
            this.sorgu = 1;
            this.harmonik = JSON.parse(thdg);
            this.powerFactor = JSON.parse(pfdg);
            asdd = JSON.parse(capower);
            document.getElementById(`capower`).innerHTML = JSON.parse(capower);
            if (this.sorgu == 1 && this.sorgu1 == 1) {
              this.Card1 = true;
            }
            this.getSliderValue();
            this.getAlarm();
          }
        }
      });
    },

    getVarkombi() {
      this.mqttClient.subscribe('/atak/varkombi');
      this.mqttClient.on('message', (topic, message) => {
        const Varkombi = JSON.parse(message);
        if (Varkombi.length == 8) {
          let r1ia = (Varkombi[0] * 0.01).toFixed(2);
          let r1ca = (Varkombi[1] * 0.01).toFixed(2);
          let s2ia = (Varkombi[2] * 0.01).toFixed(2);
          let s2ca = (Varkombi[3] * 0.01).toFixed(2);
          let t3ia = (Varkombi[4] * 0.01).toFixed(2);
          let t3ca = (Varkombi[5] * 0.01).toFixed(2);
          let inductive = (Varkombi[6] * 0.01).toFixed(2);
          let capacitive = (Varkombi[7] * 0.01).toFixed(2);

          if ('/atak/varkombi' == topic) {
            this.rFaziI = JSON.parse(r1ia);
            this.rFaziK = JSON.parse(r1ca);
            this.sFaziI = JSON.parse(s2ia);
            this.sFaziK = JSON.parse(s2ca);
            this.tFaziI = JSON.parse(t3ia);
            this.tFaziK = JSON.parse(t3ca);
            this.kapasitif = JSON.parse(inductive);
            this.enduktif = JSON.parse(capacitive);
          }
        }
      });
    },

    getAlarm() {
      do {
        this.badgecolor = asd == 0 ? 'red' : 'green';

        if (this.harmonik < 5 && this.powerFactor <= 1.5) {
          this.types2 = 'blue';
          this.icons2 = 'mdi-check-circle';
          this.alarm2 = 'Harmonik Normal - Power Factor Normal';
        } else {
          this.types2 = 'red';
          this.icons2 = 'mdi-alert-circle';
          this.alarm2 = 'İç tüketim GES üretiminden daha büyük.';
        }
        if (asd > asdd) {
          this.types = 'green';
          this.icons = 'mdi-check-circle';
          this.alarm = 'GES üretimi iç tüketimden daha büyük.';
          this.sorgu1 = 2;
        } else {
          this.types = 'red';
          this.icons = 'mdi-alert-circle';
          this.alarm = 'İç tüketim GES üretiminden daha büyük.';
          this.sorgu1 = 2;
        }
      } while (this.sorgu === 1 && this.sorgu1 === 1);
    },

    getAnalizor() {
      this.mqttClient.unsubscribe('/atak/ges1');
      this.mqttClient.subscribe('/atak/analizor'); //your mqtt topic
      this.mqttClient.on('message', function (topic, message) {
        const Analizor = JSON.parse(message);

        if (Analizor.length == 26) {
          let z = (Analizor[0] / 1000).toFixed(1);
          let x = (Analizor[1] / 1000).toFixed(1);
          let y = (Analizor[2] / 1000).toFixed(1);
          let q = (Analizor[3] / 1000).toFixed(1);
          let v1 = (Analizor[4] / 1).toFixed(1);
          let v2 = (Analizor[5] / 1).toFixed(1);
          let v3 = (Analizor[6] / 1).toFixed(1);
          let v4 = (Analizor[7] / 1).toFixed(1);
          let v5 = (Analizor[8] / 1).toFixed(1);
          let v6 = (Analizor[9] / 1).toFixed(1);
          let i1 = (Analizor[10] / 1).toFixed(1);
          let i2 = (Analizor[11] / 1).toFixed(1);
          let i3 = (Analizor[12] / 1).toFixed(1);
          let i4 = (Analizor[13] / 1).toFixed(1);
          let p1 = (Analizor[14] / 1).toFixed(1);
          let p2 = (Analizor[15] / 1).toFixed(1);
          let p3 = (Analizor[16] / 1).toFixed(1);
          let p4 = (Analizor[17] / 1).toFixed(1);
          let p5 = (Analizor[18] / 1).toFixed(1);
          let p6 = (Analizor[19] / 1).toFixed(1);
          let p7 = (Analizor[20] / 1).toFixed(1);
          let p8 = (Analizor[21] / 1).toFixed(1);
          let f = (Analizor[22] / 1).toFixed(1);
          let thd = (Analizor[23] / 1).toFixed(3);
          let pf1 = (Analizor[24] / 1).toFixed(3);
          let pf2 = (Analizor[25] / 1).toFixed(1);

          document.getElementById('z').innerHTML = JSON.parse(z);
          document.getElementById('x').innerHTML = JSON.parse(x);
          document.getElementById('y').innerHTML = JSON.parse(y);
          document.getElementById('q').innerHTML = JSON.parse(q);
          document.getElementById('v1').innerHTML = JSON.parse(v1);
          document.getElementById('v2').innerHTML = JSON.parse(v2);
          document.getElementById('v3').innerHTML = JSON.parse(v3);
          document.getElementById('v4').innerHTML = JSON.parse(v4);
          document.getElementById('v5').innerHTML = JSON.parse(v5);
          document.getElementById('v6').innerHTML = JSON.parse(v6);
          document.getElementById('i1').innerHTML = JSON.parse(i1);
          document.getElementById('i2').innerHTML = JSON.parse(i2);
          document.getElementById('i3').innerHTML = JSON.parse(i3);
          document.getElementById('i4').innerHTML = JSON.parse(i4);
          document.getElementById('p1').innerHTML = JSON.parse(p1);
          document.getElementById('p2').innerHTML = JSON.parse(p2);
          document.getElementById('p3').innerHTML = JSON.parse(p3);
          document.getElementById('p4').innerHTML = JSON.parse(p4);
          document.getElementById('p5').innerHTML = JSON.parse(p5);
          document.getElementById('p6').innerHTML = JSON.parse(p6);
          document.getElementById('p7').innerHTML = JSON.parse(p7);
          document.getElementById('p8').innerHTML = JSON.parse(p8);
          document.getElementById('f').innerHTML = JSON.parse(f);
          document.getElementById('thd').innerHTML = JSON.parse(thd);
          document.getElementById('pf1').innerHTML = JSON.parse(pf1);
          document.getElementById('pf2').innerHTML = JSON.parse(pf2);
        }
      });
    },

    getGES() {
      this.mqttClient.unsubscribe('/atak/analizor');
      this.mqttClient.subscribe('/atak/inv'); //your mqtt topic
      this.mqttClient.on('message', function (topic, message) {
        const INV = JSON.parse(message);
        if (INV.length == 4) {
          let etotal = (INV[0] / 10000).toFixed(1);
          let edaily = (INV[1] / 10).toFixed(1);
          let cpower = (INV[2] / 1000).toFixed(1);
          let carbon = (INV[3] / 100000).toFixed(1);
          if ('/atak/inv' == topic) {
            document.getElementById(`etotal`).innerHTML = JSON.parse(etotal);
            document.getElementById(`edaily`).innerHTML = JSON.parse(edaily);
            document.getElementById(`cpower`).innerHTML = JSON.parse(cpower);
            document.getElementById(`carbon`).innerHTML = JSON.parse(carbon);
          }
        }
      });
      this.mqttClient.subscribe('/atak/ges1'); //your mqtt topic
      this.mqttClient.on('message', function (topic, message) {
        const GES1 = JSON.parse(message);
        if (GES1.length == 3) {
          let g1p1 = (GES1[0] / 1000).toFixed(1);
          let g1pd1 = (GES1[1] / 100).toFixed(0);
          let g1pt1 = (GES1[2] / 100000).toFixed(1);
          if ('/atak/ges1' == topic) {
            document.getElementById(`g1p1`).innerHTML = JSON.parse(g1p1);
            document.getElementById(`g1pd1`).innerHTML = JSON.parse(g1pd1);
            document.getElementById(`g1pt1`).innerHTML = JSON.parse(g1pt1);
          }
        }
      });
      this.mqttClient.subscribe('/atak/ges2'); //your mqtt topic
      this.mqttClient.on('message', function (topic, message) {
        const GES2 = JSON.parse(message);
        if (GES2.length == 3) {
          let g2p1 = (GES2[0] / 1000).toFixed(1);
          let g2pd1 = (GES2[1] / 100).toFixed(0);
          let g2pt1 = (GES2[2] / 100000).toFixed(1);
          if ('/atak/ges2' == topic) {
            document.getElementById(`g2p1`).innerHTML = JSON.parse(g2p1);
            document.getElementById(`g2pd1`).innerHTML = JSON.parse(g2pd1);
            document.getElementById(`g2pt1`).innerHTML = JSON.parse(g2pt1);
          }
        }
      });
      this.mqttClient.subscribe('/atak/ges3'); //your mqtt topic
      this.mqttClient.on('message', function (topic, message) {
        const GES3 = JSON.parse(message);
        if (GES3.length == 3) {
          let g3p1 = (GES3[0] / 1000).toFixed(1);
          let g3pd1 = (GES3[1] / 100).toFixed(0);
          let g3pt1 = (GES3[2] / 100000).toFixed(1);
          if ('/atak/ges3' == topic) {
            document.getElementById(`g3p1`).innerHTML = JSON.parse(g3p1);
            document.getElementById(`g3pd1`).innerHTML = JSON.parse(g3pd1);
            document.getElementById(`g3pt1`).innerHTML = JSON.parse(g3pt1);
          }
        }
      });
      this.mqttClient.subscribe('/atak/ges4'); //your mqtt topic
      this.mqttClient.on('message', function (topic, message) {
        const GES4 = JSON.parse(message);
        if (GES4.length == 3) {
          let g4p1 = (GES4[0] / 1000).toFixed(1);
          let g4pd1 = (GES4[1] / 100).toFixed(0);
          let g4pt1 = (GES4[2] / 100000).toFixed(1);
          if ('/atak/ges4' == topic) {
            document.getElementById(`g4p1`).innerHTML = JSON.parse(g4p1);
            document.getElementById(`g4pd1`).innerHTML = JSON.parse(g4pd1);
            document.getElementById(`g4pt1`).innerHTML = JSON.parse(g4pt1);
          }
        }
      });
    },
  },
};
</script>
